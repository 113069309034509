/* eslint-disable */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { sendEnquiry } from '../../redux/actions/productActions';
import { useAppDispatch, useAppSelector } from "../../redux/hook"
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { uploadFile } from "../../redux/actions/enquiryActions"
// import { useParams } from 'react-router-dom';
// import NotifyToast from './NotificationToast';
// import { getCustomerDetails } from '../../redux/actions/loginActions';
// import useEnquiryValidation from '../SingleProductView/hooks/useEnquiryValidation';
// import { fetchSingleProducts } from '../../redux/actions/productActions';

interface Props {
    id: any,
    product: any,
    skuValueString?: any
}

//! A component that renders a button that opens a modal for sending an enquiry
function QuoteButton({ id, product, skuValueString }: Props) {
    console.log(skuValueString, "skuValueString122")
    const session = sessionStorage.getItem("session") || localStorage.getItem("session")
    const userId = sessionStorage.getItem("userId") || localStorage.getItem("userId")
    const productEnq = useAppSelector((state: any) => state?.enquiry?.productEnquiry);
    const customer = useAppSelector((state: any) => state?.customerLogin);
    const skuData = useAppSelector((state: any) => state?.skuReducer?.skuData?.data);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [desc, setDesc] = useState("")
    const [fileUploadData, setFileUploadData] = useState<any>(null);
    const custId = customer?.customer?.response?.customer?.id
    const enqId = productEnq?.response?.enquiry?.id
    const status = 7  //"Enquiry received"
    const description = desc
    const productId = id
    const sFlag = localStorage.getItem("sFlag")

    const sendEnquiryData = {
        customer_id: custId,
        product_id: productId,
        description: description,
        status: status,
        user_id: userId,
        doe: new Date(),
        sku_id: skuData?.id,
        sku_value: skuData?.value,
        files: skuData?.file,

    }

    const handleShow = () => {
        setShow(true)
    };

    const handleClose = () => { setShow(false) }

    const handleSubmit = () => {
        setShow(false);
        dispatch(sendEnquiry(sendEnquiryData))
    }

    useEffect(() => {
        if (sFlag === "true") {
            setShow(true);
        }
    }, [sFlag])

    useEffect(() => {
        const fileData: any = {
            type: "Enquiry",
            file: fileUploadData,
            typeId: enqId,
            uploadedBy: userId,
            tagId: 8,
            needApproval: true

        }

        console.log(enqId, "ENQUIRY ID")
        if (productEnq) {
            console.log(enqId, "ENQUIRY ID")
            const formData = new FormData();

            for (let key in fileData) {
                formData.append(key, fileData[key])
            }
            if (formData && fileData.file) {
                dispatch(uploadFile(formData))
            }
            console.log(formData, "formDtata")
        }

        localStorage.setItem("sFlag", "false")
    }, [productEnq, enqId])


    return (
        <>
            {userId && session !== "null" && skuData?.value ? (
                < div >
                    <Button className='brownBtn' variant="brownBtn" onClick={handleShow}>Request a quote</Button>
                    <Modal show={show} onHide={handleClose}>

                        <Modal.Header closeButton>
                            <Modal.Title >Request for Quote</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <p className='article-rfq'>Send us your query for <span className='prdct-name'>{`${product?.name}`}</span></p>
                                {/* <p>SKU =  {skuData?.value}</p> */}
                                <p><b>SKU :</b>  {skuValueString.current} value : {skuData?.value}</p>
                                <Form.Control as="textarea" onChange={(e: any) => setDesc(e.target.value)} rows={3} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Please upload files if any! </Form.Label>
                                <Form.Control id='fileUpload' type="file" onChange={(e: any) => {
                                    setFileUploadData(e.currentTarget.files[0])
                                }} />
                            </Form.Group>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button className='brownBtn' variant="primary" onClick={handleSubmit}>
                                Send Enquiry
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div >

            ) : (<Button className='brownBtn' variant="brownBtn" onClick={() => {
                if (!skuData?.value && userId && session !== "null") {
                    toast.info("Please select SKU to continue")
                    return
                } else {
                    toast.info("Please login to continue")
                    localStorage.setItem("sFlag1", "true")
                    navigate("/login", { state: { id: id, form: "/productDetails" } })
                }

            }}>Request a quote</Button>)
            }
        </>
    )

}

export default QuoteButton