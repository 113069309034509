import { ActionTypes } from "../constants/action-types";

const initialstate = {
    products: [],
    singleProductData: {},
    productEnquiry: {},
    enquiryId: null,
    fileData: {},
    skuFiles: {},
    error: {}

}

// this is the reducer function that will return the state of the application for all products
export const productReducer = (state = initialstate, action: any) => {
    switch (action.type) {
        case ActionTypes.SET_PRODUCTS:
            return { ...state, products: action.payload };
        default:
            return { ...state };
    }
}

// this is the reducer function that will return the state of the application for a single product
export const selectedProductReducer = (state = {}, action: any) => {
    switch (action.type) {
        case ActionTypes.SELECTED_PRODUCT:
            return { ...state, singleProductData: action.payload };
        case ActionTypes.REMOVE_SELECTED_PRODUCT:
            return {};
        default:
            return { ...state };
    }
}


// this is the reducer function that will post the the enquiry data to the database
export const productEnquiryReducer = (state = {}, action: any) => {
    switch (action.type) {
        case ActionTypes.CREATE_ENQUIRY_SUCCESS:
            return { ...state, productEnquiry: action.payload, enquiryId: action.payload?.response?.enquiry?.id };
        case ActionTypes.CREATE_ENQUIRY_FAIL:
            return { ...state, productEnquiry: action.payload };
        case ActionTypes.CLEAR_ENQUIRY_ID:
            console.log("case callled")
            return { ...state, productEnquiry: {} }
        default:
            return { ...state };
    }

}

// this is the reducer function that will post file data to the database
export const fileUploadReducer = (state: {}, action: any) => {
    console.log("file uploaded")
    switch (action.type) {
        case ActionTypes.UPLOAD_FILE:
            return { ...state, fileData: action.payload };
        case ActionTypes.UPLOAD_FILE_FAIL:
            return { ...state, fileData: action.payload }

    }
}


export const skuReducer = (state = {}, action: any) => {
    switch (action.type) {

        case ActionTypes.SET_SKU:
            return { ...state, skuData: action.payload };
        case ActionTypes.FETCH_SKU_FILE_SUCCESS:
            return { ...state, skuFiles: action.payload?.data }
        case ActionTypes.FETCH_SKU_FILE_FAIL:
            return { ...state, error: action.payload?.error }
        default:
            return { ...state };
    }
}
