import productApi from "../../apis/productApi";
import { ActionTypes } from "../constants/action-types";


// const url = "http://localhost:5000/api/v1/login/"
// const url = "https://faraday.arachnomesh.com/api/v1/login/"


//action will make the request t0 get the otp
export const getOtpRequest = (email: any,type?:string) => async (dispatch: any) => {
    try {
        const response = await productApi.post("login/", { email,type})
        // console.log(response.data, "response.data")
        dispatch({
            type: ActionTypes.OTP_REQUEST_SUCCESS,
            payload: {
                data: response.data,
                email: email
            }
        })
    } catch (error: any) {
        dispatch({
            type: ActionTypes.OTP_REQUEST_FAIL,
            payload: { error: error?.response?.data?.errorMsg }
        })
    }
}

export const resetOtpRequest = ()=>(dispatch:any)=>{
    dispatch({type:ActionTypes.RESET_OTP_REQUEST,payload:{}})
}

//action will help in verifying the request the otp
export const verifyOtpRequest = (email: string, key: string, otp: string,type?:string) => async (dispatch: any) => {

    try {
        const response = await productApi.post("login/otp", { email, key, otp,type:type ?? '' })

        dispatch({
            type: ActionTypes.VERIFY_OTP_SUCCESS, payload: {
                data: response.data,
                email: email
            }
        })
    } catch (error) {
        dispatch({
            type: ActionTypes.VERIFY_OTP_FAIL,
            payload: { error }
        })
    }
}

export const reSendOtp = (key: string, email: string) => async (dispatch: any) => {
    try {
        const response = await productApi.post("login/resendOtp", { key, email })
        dispatch({
            type: ActionTypes.RESEND_OTP_SUCCESS, payload: {
                data: response.data,
                email: email
            }
        })
    }
    catch (error) {
        dispatch({
            type: ActionTypes.RESEND_OTP_FAIL,
            payload: { error }
        })
    }

}

//action to fetch customer
export const fetchCustomer = (id: any) => async (dispatch: any) => {
    try {
        const response = await productApi.get(`/customer/${id}`)
        // console.log(response.data, "fetchCustomer")
        dispatch({
            type: ActionTypes.FETCH_CUSTOMER,
            payload: response?.data,
        })
    } catch (error) {
        dispatch({
            type: ActionTypes.FETCH_CUSTOMER_FAIL,
            payload: { error }
        })
    }
}

//disptach to logout 
export const logout = () => async (dispatch: any) => {

    try {
        dispatch({
            type: ActionTypes.LOGOUT,
            payload: {}
        })
    } catch (error) {
        console.log(error);

    }
}

//! Get customer details

export const getCustomerDetails = (id: any) => async (dispatch: any) => {

    try {
        // console.log(localStorage.getItem('session'), "session123")
        const response = await productApi.get(`/customer/${id}`)
        // console.log(response.data, "getCustomerDetails")

        dispatch({
            type: ActionTypes.GET_USER_DETAIL, payload: {
                data: response.data,
            }
        })
    } catch (error) {
        dispatch({
            type: ActionTypes.GET_USER_DETAIL_FAIL,
            payload: { error }
        })
    }
}

//! Action to create/register new customer


export const createOrganization = (organization: any, customer: any) => async (dispatch: any) => {
    try {
        const existingOrg = await productApi.get(`/organization?page=1&limit=1000&sortBy=id&sort=DESC&searchBy=${organization?.name}`)

        const allRows = existingOrg?.data?.response?.organization?.rows
        let isOrgExist: any;

        allRows.forEach((org: any) => {
            if (org.name === organization?.name) {
                isOrgExist = org
                console.log(isOrgExist, "isOrgExist")
            }
        });

        console.log(existingOrg?.data?.response?.organization?.rows, "existingOrg.data")

        if (isOrgExist) {
            console.log(organization, "custwithorg")

            customer.organization_id = isOrgExist.id
            customer.status = "Enable"         

            const response = await productApi.post('/customer', { customer })
            dispatch({
                type: ActionTypes.CREATE_USER_SUCCESS,
                payload: {
                    data: response.data
                }
            })

            
        }
        else {
            const response = await productApi.post('/organization', { organization })
            dispatch({
                type: ActionTypes.CREATE_ORGANIZATION,
                payload: {
                    data: response.data
                }
            })
        }

    } catch (error) {
        dispatch({
            type: ActionTypes.CREATE_ORGANIZATION_FAIL,
            payload: { error }
        })
    }
}
export const createCustomer = (customer: any) => async (dispatch: any) => {
    try {
        const response = await productApi.post('/customer', { customer })
        dispatch({
            type: ActionTypes.CREATE_USER_SUCCESS,
            payload: {
                data: response.data
            }
        })

    } catch (error) {
        dispatch({
            type: ActionTypes.CREATE_USER_FAIL,
            payload: { error }
        })
    }
}

//reset the state
export const resetState = () => async (dispatch: any) => {
    try {
        dispatch({
            type: ActionTypes.RESET_STATE,
            payload: {}
        })
    } catch (error) {
        console.log(error);

    }
}

export const createApproval = (approval: any) => async (dispatch: any) => {
    try {
        const response = await productApi.post('/approval', { approval })
        dispatch({
            type: ActionTypes.CREATE_APPROVAL_SUCCESS,
            payload: {
                data: response.data
            }
        })
    } catch (error) {
        dispatch({
            type: ActionTypes.CREATE_APPROVAL_FAIL,
            payload: { error }
        })
    }
}

