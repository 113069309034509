import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook"
import {
    getOtpRequest,
    verifyOtpRequest,
    logout,
    reSendOtp,
    resetState
} from "../../../redux/actions/loginActions";

export default function useLogin() {

    const loginResponse = useAppSelector((state: any) => state.customerLogin?.response?.response?.customer);
    const customerData = useAppSelector((state: any) => state.customerLogin);
    const session = customerData.session;
    const dispatch = useAppDispatch();
    const [error, setError] = useState<any>();
    const [sendSuccess, setSuccess] = useState<any>();



    const { email, loading, key, loginError, success, resendOtpSuccess } = useAppSelector(
        (state) => {
            return {
                email: customerData.email,
                loading: customerData.loading,
                key: customerData.key,
                loginError: customerData.error,
                isValidSession: customerData.isValidSession,
                success: customerData.success,
                resendOtpSuccess: customerData.resendOtpSuccess,
            };
        }
    );

    const sendOtp = (email: any) => {
        dispatch(getOtpRequest(email));
    };

    const resendOtp = (key: any, email: any) => {
        dispatch(reSendOtp(key, email));
    }

    const verifyOtp = async (otp: any, email: any, key: any) => {
        dispatch(verifyOtpRequest(email, key, otp));
    };

    const logOut = async () => {
        dispatch(logout())
    }

    const reset = async () =>{
        dispatch(resetState())
    }


    useEffect(() => {
        if (loginError) {
            setError(loginError);
        }
    }, [loginError])

    useEffect(() => {
        setSuccess(success);
    }, [success])


    const setSessionStorage = () => {

        // console.log("session storage");
        sessionStorage.setItem("session", session.sessionId);
        sessionStorage.setItem("userId", session.userId);
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("key", key);

    }

    const setLocalStorage = () => {

        // console.log("local storage");
        sessionStorage.clear();
        localStorage.setItem("session", session.sessionId);
        localStorage.setItem("userId", session.userId);
        localStorage.setItem("email", email);
        localStorage.setItem("key", key);

    }

    return {
        email,
        loading,
        key,
        error,
        // redirectUrl,
        sendOtp,
        resendOtp,
        verifyOtp,
        // handleEdit,
        // token,
        setError,
        loginError,
        logOut,
        sendSuccess,
        setSuccess,

        setSessionStorage,
        setLocalStorage,
        resendOtpSuccess,
        reset,
        loginResponse
        // removeToken,
        // getEmail
    };
}
