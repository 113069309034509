import {  useAppSelector } from "../../../redux/hook"

export default function useSkuMatrix() {


    const skuFiles = useAppSelector((state: any) => state.skuReducer?.skuFiles?.response?.data?.files);

    return { skuFiles }


}