/* eslint-disable */
import { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
// import { fetchProducts } from "../../redux/actions/productActions";
import { useAppDispatch, useAppSelector } from "../../redux/hook"
// import QuoteButton from '../Misc/QuoteButton';
// import { Allimages } from "../Misc/AllProductDetails"
// import Button from 'react-bootstrap/Button';
// import img from '../../assets/img/img1.webp';
// import ProductParam from './ProductParamt';
import { imageUrl, imageUrl2 } from '../../constants/url';
import defaultImg from "../../assets/img/Noproducts/noproduct.webp"

import { toast } from 'react-toastify';
import NotifyToast from '../Misc/NotificationToast';
import { clearEnquiryId } from "../../redux/actions/enquiryActions"
import { useNavigate } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function ProductCard({ allproducts }: any) {
    // console.log(allproducts, 'allproducts')

    const navigate = useNavigate();
    // const products = useAppSelector((state: any) => state.allProducts.products);
    const dispatch = useAppDispatch();


    const productEnq = useAppSelector((state: any) => state?.enquiry?.productEnquiry);
    const enqId = productEnq?.response?.enquiry?.id

    useEffect(() => {
        if (enqId) {
            // console.log(productEnq, "enqId")
            toast.success(<div className='link_toast'>
                {/* Enquiry sent successfully click here to view */}
                <NotifyToast />
            </div>, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 15000,
                closeOnClick: false,
                className: 'customNotifyToast',
            })

            dispatch(clearEnquiryId())
        }

    }, [enqId])

    const MAX_LENGTH = 220;

    return (

        <>

            {

                allproducts?.map((item: any) => {

                    const myString = item.html_desc;
                    const div = document.createElement('div');
                    div.innerHTML = myString;
                    const text = div.textContent;
                    item.text = text

                    const renderTooltip = (props: any) => (
                        <Tooltip id="button-tooltip" className='product_card_tooltip' {...props}>
                            {item.text}
                        </Tooltip>
                    );

                    return (

                        <Col lg={3} >
                            <div className="productCard productDetailCards" key={item.id}>
                                {/* <Link to={`/singleDetail/${item.id}`}><img src={Allimages[item.id - 1]?.img} alt='img' /></Link> */}
                                <a href={`/singleDetail/${item.id}`}>
                                    <img src={item?.productImage?.fullPath ? `${imageUrl}` + item?.productImage?.fullPath : defaultImg}
                                        srcSet={item?.productImage?.fullPath ? `${imageUrl2}` + item?.productImage?.fullPath : defaultImg}
                                        alt="img" />
                                </a>
                                <div className="details">
                                    <div className="detailsHeading">
                                        <a href={`/singleDetail/${item.id}`}> <h5>{item.name}</h5> </a>
                                        {/* <span className="availablity">In Stock</span> */}
                                    </div>
                                    <div className="contentDiv">

                                        <div className='contnt'>

                                            <div className="description">
                                                <h5>PRODUCT DESCRIPTION</h5>

                                            </div>

                                            {item.text.length < MAX_LENGTH ?
                                                (
                                                    <div dangerouslySetInnerHTML={{ __html: item.html_desc }} ></div>
                                                )
                                                :
                                                <OverlayTrigger
                                                    placement="top"
                                                    // delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip}
                                                >
                                                    <div className='hoverContent'>
                                                        <div className='custom_9' dangerouslySetInnerHTML={{ __html: item.html_desc }} >
                                                        </div>
                                                        <span className='dot'>.....</span>
                                                    </div>
                                                </OverlayTrigger>
                                            }
                                            {/* <ProductParam item={item} /> */}

                                        </div>

                                    </div>

                                    {/* DO NOT DELETE  ... uncomment the css line 1105 when u uncomment this*/}

                                    {/* <div className="btnDiv">
                                        <QuoteButton id={item.id} product={item} />
                                    </div> */}
                                </div>

                            </div>
                        </Col >
                    )
                })
            }
        </>

    )
}

export default ProductCard; 