import { useEffect, useState } from 'react'
import "../../assets/css/customClass.css"
import "../../assets/css/main.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import Otp from "./Otp";
import logo2 from "../../assets/img/logo.png"
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useAppSelector } from "../../redux/hook"
import useLogin from "./hooks/useLogin";
import { toast } from "react-toastify";
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { useLocation, useNavigate } from 'react-router-dom';


function Login() {
    const location = useLocation();
    const navigate = useNavigate();
    console.log(location, "location in login")
    const { state: custEmail } = location;
    console.log(custEmail, "custEmail in login")

    const sFlag = location?.state?.sFlag
    localStorage.setItem("sFlag", sFlag)

    const productId = location.state?.id;
    const [email, setEmail] = useState("");
    useEffect(() => {
        if (typeof custEmail === "string") {
            setEmail(custEmail)
        }
    }, [custEmail])
    // const [rememberMe, setRemberMe] = useState(true);


    const { key } = useAppSelector((state: any) => state.customerLogin);
    const { sendOtp, error, setError, sendSuccess, setSuccess, loginResponse } = useLogin();
    const [otpFailed, setOtpFailed] = useState(false);

    useEffect(() => {
        if (error) {
            setError("");
            toast.error(error);
            setOtpFailed(true);

        }
        // eslint-disable-next-line
    }, [error]);

    const verifySuccessOtp = () => {
        if (sendSuccess) {
            setSuccess(false);
        }
    }


    useEffect(() => {
        console.log(loginResponse, "loginResponse?.status")
        if (loginResponse?.status === "Disable") {
            toast.info("Your account has been disabled by admin.")
            navigate("/")
        } else {
            verifySuccessOtp()
        }
        // eslint-disable-next-line
    }, [loginResponse])

    const handleSubmit = (e: any) => {
        e.preventDefault();
        sendOtp(email);
    }


    return (
        <>
            {!key ? (
                <div className="login-container2">
                    <div className="main-container">
                        <div className="login__logo2">
                            <NavLink to='/'> <img src={logo2} alt='logo' /></NavLink>
                        </div>
                        <div className='form-holder'>
                            <Container className='form-container2'>
                                <Form>
                                    <h2>Login</h2>
                                    <p id='welcome'>Welcome, Please enter your details!</p>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control className='email-input2' type="email" placeholder="johndoe@example.com" value={email} onChange={e => setEmail(e.target.value)} />
                                    </Form.Group>

                                    {/*! -------------------------REMEMBER ME OPTION------------------------- */}
                                    {/* <Form.Group className="mb-3 remember-me" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" label="Remember me" onChange={() => setRemberMe(!rememberMe)} defaultChecked />
                                    </Form.Group> */}
                                    {/* <p className="need-help"> <a href='#'> need help? </a></p> */}
                                    <button className='brown-btn2' type='submit' onClick={handleSubmit}>
                                        Get OTP
                                    </button>
                                    {otpFailed && <p id='register'> Login with different<span id='reg'><button className='reg' onClick={() => {
                                        navigate("/login")
                                    }}>account </button></span></p>}
                                    
                                    <p id='register'>Don't have an account? <span id='reg'><button className='reg' onClick={() => {
                                        window.location.href = "/register"
                                        navigate("/register")
                                    }}>Register</button></span></p>
                                </Form>
                            </Container>

                        </div>
                        <div className="footer-links2">
                            <ul>
                                <li><Nav.Link href='/#about-us'>About us</Nav.Link></li>
                                <li><NavLink to='/'>Privacy Policy</NavLink></li>
                                <li><NavLink to='/termsCondition'>Terms of use</NavLink></li>
                            </ul>
                            <div className="copy-right2">
                                <p>Copyright &copy; 2022. All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>

            ) : (
                <Otp rememberMe={true} id={productId ? productId : ""} />
            )}
        </>
    )
}

export default Login