/* eslint-disable */
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
// import img from '../../assets/img/bg3.webp';
// import star1 from "../../assets/img/starYellow.png";
// import star2 from "../../assets/img/starGrey.png";
// import share from "../../assets/img/share.png";
// import compare from "../../assets/img/compare.png";
// import wishlist from "../../assets/img/wishlist.png";
import { useAppDispatch, useAppSelector } from "../../redux/hook"
import { fetchProducts, fetchSingleProducts } from "../../redux/actions/productActions"
import { useParams } from 'react-router-dom';
// import QuoteButton from '../Misc/QuoteButton';
// import { useLocation } from 'react-router-dom';

import arrow from '../../assets/img/arrow_right.png';
// import { Allimages } from '../Misc/AllProductDetails';
import { baseUrl, imageUrl, imageUrl2 } from '../../constants/url';
// import defaultImg from "../../assets/img/productBanner.png"
import defaultImg from "../../assets/img/Noproducts/productBanner.webp"

function Banner() {

    const singleData = useAppSelector((state: any) => state.product.singleProductData);
    console.log(singleData, "singleDataImg")
    // const products = useAppSelector((state: any) => state.allProducts.products);
    const dispatch = useAppDispatch();
    const [urlPath, setUrlPath] = useState<any>("")

    useEffect(() => {
        dispatch(fetchProducts())
    }, [])

    const { id } = useParams()
    // const paramId: any = id;

    useEffect(() => {
        dispatch(fetchSingleProducts(id))
    }, [id])

    // useEffect(() => {
    //     const hostName = window.location.hostname
    //     if (hostName === "fabricationbazar.com") {
    //         setUrlPath("https://admin.fabricationbazar.com/")
    //     } else if (hostName === "fb.arachnomesh.com") {
    //         setUrlPath("https://faraday-admin.arachnomesh.com/")
    //     } else if (hostName === "localhost") {
    //         setUrlPath("http://localhost:5005/")
    //     }
    // }, [])

    return (
        <div className="bannerSec">
            <img src={singleData?.productImage?.fullPath ? `${imageUrl}` + singleData?.productImage?.fullPath : defaultImg} 
            srcSet={singleData?.productImage?.fullPath ? `${imageUrl2}` + singleData?.productImage?.fullPath : defaultImg}
            className='bannerImg' alt='banner'
            />
            <div className='bannerContent'>
                <Container>
                    <div className="bannerContentRow dflxCstmCntr">
                        <div className="productDetails">
                            <div className='breadCrumb pb-0'>

                                <ul><li>Home</li>
                                    <li><img src={arrow} alt='arrow' /></li>
                                    <li>Products</li>
                                    <li><img src={arrow} alt='arrow' /></li>
                                    <li>{singleData?.name}</li>
                                </ul>

                            </div>
                            <div className="bannerHeading d-flex">
                                <h1 className='text-white'>{singleData?.name}</h1>
                            </div>
                        </div>
                        <div className="productAction">
                            {/* <QuoteButton product={product} id={paramId} /> */}
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
}

export default Banner; 