import "./assets/css/main.css"
import Login from "./components/Login_page/Login2"
import ProductDetail from './components/ProductDetail'
import SingleDetail from './components/SingleProductView'
import { Navigate, Route, Routes } from 'react-router-dom'
import CustomForms from './components/CustomForm/CustomForms'
import HomePage from './components/HomePage'
import TermsPage from './components/Terms_Condition/Terms'
import PrivacyPage from "./components/PrivacyPolicy/Privacy"
import { useEffect } from 'react'
import Registration from './components/Login_page/Registration'
import 'react-image-lightbox/style.css';
import ReacyGA from 'react-ga'

function App() {

  const TRAKING_ID = "UA-261151332-1";
  ReacyGA.initialize(TRAKING_ID);


  useEffect(() => {
    console.log(window.location);
    if (window.location.hash.includes('about-us') || window.location.hash.includes('contact-us')) {
      const hash = window.location.hash;
      const id = hash.substring(1, hash.length);
      let addedScroll = 0;
      let element: any;
      if (id === 'contact-us') {
        addedScroll = 1200

      } else {

        addedScroll = 500
      }

      element = document.getElementById(id)
      const offsetvalue = element?.offsetTop;
      if (offsetvalue) {
        // window.scrollTo(0, offsetvalue + 200 )
        window.scrollBy(0, offsetvalue + addedScroll)
        setTimeout(() => {
          let extraElemet = document.getElementById('extraSpace');
          if (extraElemet) {

            extraElemet.style.display = "none"
          }
        }, 500);


        // window.scrollTo(0, offsetvalue + (id == 'contact-us' ? element.scrollHeight - 500 : 200))
      }

    }


    

  }, [])

  document.body.addEventListener('click',onbodyClickHandler)
  function onbodyClickHandler() {
    try {
      let toastBody: any =document.getElementsByClassName('Toastify__toast--close-on-click')
      toastBody[0].click();
    } catch (error) {
      // console.log(error)
    }
  }


  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/register" element={<Registration />} />
      {/* <Route path="/home" element={<HomePage />} /> */}
      <Route path="/form" element={<CustomForms />} />
      <Route path="/productDetail" element={<ProductDetail />} />
      <Route path="/singleDetail/:id" element={<SingleDetail />} />
      <Route path="/login" element={<Login />} />
      <Route path="/termsCondition" element={<TermsPage />} />
      <Route path="/privacyPolicy" element={<PrivacyPage />} />
      <Route path="/*" element={<Navigate to={"/"}/>} />
    </Routes>
  )
}

export default App
