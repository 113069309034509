import "swiper/css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from 'swiper';
import { Button } from "react-bootstrap";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { NavLink } from "react-router-dom";
// import { useState, useEffect } from "react";

function SliderSec() {
  // const navigate = useNavigate();


  // const [currVisits, setVisits] = useState(0)

  // useEffect(() => {
  //   fetch("https://api.countapi.xyz/hit/fb.arachnomesh.com/6c58443d-bd05-4049-a454-b7c7c99be823")
  //     .then(res => res.json()).then(data => {
  //       setVisits(data.value)
  //     })

  //   alert(currVisits)
  //   //eslint-disable-next-line
  // }, [])

  return (

    <div className="sliderSec">
      <Swiper
        effect='slide'
        autoplay={{
          delay: 8000
        }}
        speed={1200}
        initialSlide={1}
        loop={true}
        slidesPerView={1}
        navigation={true}
        pagination={true}
        modules={[Navigation, Autoplay, Pagination]}

        // Responsive breakpoints
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
          },
          // when window width is >= 767px
          767: {
            slidesPerView: 1,
          },

          // when window width is >= 959px
          959: {
            slidesPerView: 1,
          }
        }}
      >


        <SwiperSlide>
          <div className="slideOuter">

            <video autoPlay={true} playsInline muted loop width='100%' height='100%'>
              <source type="video/mp4" src={require('../../assets/img/videos/video5.mp4')}></source>
            </video>


            <div className="sliderContent">
              <h2>India’s first tech enabled full-stack <br /> platform  disrupting the
                steel  <br /> fabrication industry. </h2>
              <p>Unleashing the power of AI & ML</p>

              <div>

                <a href="#applicationareas"> <Button className='brownBtn' variant="primary">Visit Our Application Areas</Button> </a>

              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slideOuter">
            <video autoPlay={true} playsInline muted loop width='100%' height='100%'>
              <source type="video/mp4" src={require('../../assets/img/videos/first.mp4')}></source>
            </video>
            <div className="sliderContent">
              <h2>One Stop solution for b2b Steel </h2>
              <h1>Fabricated products</h1>

              <div>

                <NavLink to='/productDetail'><Button className='brownBtn' variant="primary">Visit Our Products</Button> </NavLink>

              </div>
            </div>
          </div>
        </SwiperSlide>



        <SwiperSlide>
          <div className="slideOuter">
            <video autoPlay={true} playsInline muted loop width='100%' height='100%'>
              <source type="video/mp4" src={require('../../assets/img/videos/3.mp4')}></source>
            </video>
            <div className="sliderContent">
              <h2>empowering  </h2>
              <h1>Micro & Small Fabricators</h1>
              <div className="counter">
                <ul>
                  <li>
                    <h2>200 <span>+</span></h2>
                    <p>Fabrication Vendors</p>
                  </li>

                  <li>
                    <h2>19 <span>+</span></h2>
                    <p>Logistics Vendors</p>
                  </li>

                  <li>
                    <h2>60000 <span> + MT</span></h2>
                    <p>Fabrication Capacity</p>
                  </li>
                </ul>
              </div>
              {/* <p>80% Vendor Concentration Currently in 5-6 States</p> */}
            </div>
          </div>
        </SwiperSlide>

        {/* <SwiperSlide>
          <div className="slideOuter">
            <video autoPlay={true} playsInline muted loop width='100%' height='100%'>
              <source type="video/mp4" src={require('../../assets/img/videos/circular.mp4')}></source>
            </video>
            <div className="sliderContent">
              <h2>Enabling Circular </h2>
              <h1>economy</h1>
            </div>
          </div>
        </SwiperSlide> */}



      </Swiper>
    </div>



  )
}

export default SliderSec