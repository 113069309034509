/* eslint-disable */
import { useEffect, useState } from 'react'
import "../../assets/css/customClass.css"
import "../../assets/css/main.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import logo2 from "../../assets/img/logo.png"
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hook"
import useLogin from "./hooks/useLogin";
import { toast } from "react-toastify";
import { NavLink } from 'react-router-dom';
import OtpTimer from 'otp-timer'
import editIcon from "../../assets/img/edit512.png"

function Otp({ rememberMe, id }: { rememberMe: boolean, id: any }) {

    const { key, isValidSession, email, verifySuccess } = useAppSelector((state: any) => state.customerLogin);

    const { verifyOtp, resendOtp, sendSuccess, setSuccess, setSessionStorage, setLocalStorage, resendOtpSuccess, loginResponse } = useLogin();

    const [otp, setOtp] = useState("");
    const [count, setCount] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        if (sendSuccess && loginResponse?.status !== "Disable") {
            toast.success("OTP sent successfully");
            setSuccess(false);
        }
    }, [sendSuccess, verifySuccess, loginResponse]);

    useEffect(() => {
        if (id && verifySuccess) {
            window.location.href = `/singleDetail/${id}`

        }
    }, [id, verifySuccess])

    useEffect(() => {
        if (resendOtpSuccess) {
            toast.success("OTP sent successfully");
            setSuccess(false);
        }

    }, [resendOtpSuccess])

    const [display, setDisplay] = useState(false)

    const handleResendOtp = (e: any) => {
        resendOtp(key, email)
        setCount(count + 1)


    }

    useEffect(() => {
        console.log(display, "display")

    }, [display])


    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!otp) {
            toast.error("Please enter OTP");
        } else {
            verifyOtp(otp, email, key);
        }
    }

    useEffect(() => {
        if (isValidSession) {
            if (!rememberMe) {
                setSessionStorage()
            } else {
                setLocalStorage()
            }
            navigate("/");
        }

        // eslint-disable-next-line
    }, [isValidSession, rememberMe])


    return (

        <div className="login-container2">
            <div className="main-container">
                <div className="login__logo2">
                    <NavLink to='/'> <img src={logo2} alt='logo' /></NavLink>
                </div>
                <div className='form-holder'>
                    <Container className='form-container2'>
                        <Form>
                            <h2>Enter OTP</h2>
                            <p id='welcome'>We sent a 6 digit OTP on <span style={{ "color": "white", fontSize: "12px" }}>{email} <img className='icon' role="button" onClick={() => {
                                navigate(0)
                            }} src={editIcon} alt="edit" /> </span></p>
                            <Form.Group className="mb-3" >
                                {/* <Form.Label>OTP</Form.Label> */}
                                <Form.Control className='email-input2' type="email" onChange={e => setOtp(e.target.value)} />
                            </Form.Group>

                            {/* <Form.Group className="mb-3 remember-me" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Remember me" />
                                <p className="need-help"> <a href='#'> need help? </a></p>
                            </Form.Group> */}
                            {count < 3 ?
                                <>
                                    <div className="resend-otp-holder">
                                        <p>Didn't receive the OTP? </p>
                                        <OtpTimer
                                            minutes={0}
                                            seconds={30}
                                            text="Resend OTP in "
                                            ButtonText="Resend OTP"
                                            resend={handleResendOtp}
                                            background="none"
                                        />
                                    </div>
                                </>
                                :
                                <p className='resend-otp'>Maximum resend OTP limit reached <br></br>
                                    please <span><button className='reset-btn-style login' onClick={() => {
                                        window.location.reload()
                                    }}> click here </button></span> to login again.</p>}

                            <button className='brown-btn2' type='submit' onClick={handleSubmit}>
                                Submit OTP
                            </button>

                        </Form>
                    </Container>

                </div>
                <div className="footer-links2">
                    <ul>
                        <li><NavLink to='/'>About us</NavLink></li>
                        <li><NavLink to='/'>Privacy Policy</NavLink></li>
                        <li><NavLink to='/termsCondition'>Terms of use</NavLink></li>
                    </ul>

                    <div className="copy-right2">
                        <p>Copyright &copy; 2022. All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div >



    )
}

export default Otp