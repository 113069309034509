import Footer from '../Footer'
import Header from '../Header'
import AboutSec from './About'
import ApplicationArea from './ApplicationArea'
import Certification from './Certification'
import ClientSec from './Clients'
import ContactUs from './ContactUs'
import FeaturesSec from './Features'
import ProductSec from './productSec'
import SliderSec from './Slider'
import VendorLogin from './VendorLogin'
import ReachGA from "react-ga"
// import TeamSec from './Teams'


function HomePage() {

    ReachGA.pageview(window.location.pathname + window.location.search)

    return (
        <div className="mainHomepage">
            <Header />
            <SliderSec />
            <ProductSec />
            <AboutSec />
            <ApplicationArea />
            <Certification />
            <FeaturesSec />
            <ClientSec />
            <VendorLogin />
            {/* <TeamSec />/ */}
            <ContactUs />
            {/* this blank div is temporary solution for scroll from inner pages on links */}
            {window.location.hash.includes('contact-us') &&
                <div id="extraSpace">
                </div>}
            <Footer />
        </div>
    )
}

export default HomePage
