import { combineReducers } from "redux";
import { productReducer, productEnquiryReducer, selectedProductReducer, skuReducer } from "./productReducer";
import loginReducer from "./loginReducer"
import { queryReducer } from "./queryReducers"

const reducers = combineReducers({
    allProducts: productReducer,
    product: selectedProductReducer,
    customerLogin: loginReducer,
    query: queryReducer,
    enquiry: productEnquiryReducer,
    skuReducer: skuReducer


});

export default reducers;