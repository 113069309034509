/* eslint-disable */
import { useState, useEffect, useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import logo2 from "../../assets/img/logo.png"
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import 'react-phone-number-input/style.css'
import * as yup from 'yup'
import "yup-phone"
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
// import { toast } from 'react-toastify';
import { createApproval, createCustomer, createOrganization, getOtpRequest, resetOtpRequest, resetState, verifyOtpRequest } from '../../redux/actions/loginActions';
import { useLocation } from 'react-router-dom';
// import { AddressService } from "@arachnomesh/forms";
import countries from "../../assets/address_data/country.json"
import cities from "../../assets/address_data/city.json"
import states from "../../assets/address_data/state.json"
import { toast } from 'react-toastify';


function Registration() {

    const customer: any = useAppSelector((state: any) => state?.customerLogin?.registeredCustomer)
    const error: any = useAppSelector((state: any) => state?.customerLogin?.error)
    const otpError = useAppSelector((state: any) => state?.customerLogin?.otpError)
    const otpSuccess = useAppSelector((state: any) => state?.customerLogin?.otpSent)
    const key = useAppSelector((state: any)=> state?.customerLogin?.key)
    const verifySuccess = useAppSelector((state: any)=> state?.customerLogin?.verifySuccess)
    const inputRef = useRef<any>(null)
    let id = customer?.response?.customer?.id
    const success = customer?.response?.success

    const [email, setEmail] = useState<any>("")
    const [isVerified,setIsVerified] = useState(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const state: any = location.state
    console.log(state)
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    // const webreg = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
    const webreg = /^((https?|ftp):\/\/)?[^\s/$.?#].[^\s]*\.(com|org|net|edu|gov|mil|biz|usa|info|name|in|museum|coop|aero|id|ca|fr|uk|de)(\.[a-z]{2,})?$/i;

    const [selectedState, setSelectedState] = useState<any>("")
    const [selectedCountry, setSelectedCountry] = useState<any>("")
    const [selectedCity, setSelectedCity] = useState<any>("")
    const organization = useAppSelector((state: any) => state.customerLogin.organization?.data?.response?.organization);
    const registeredCustomer = useAppSelector((state: any) => state.customerLogin?.registeredCustomer?.response?.customer);

    const formik = useFormik({
        initialValues: {
            name: '',
            organization: '',
            email: '',
            phone: '',
            designation: '',
            address: '',
            website: ''
        },

        validationSchema: yup.object({
            name: yup.string().required('Please enter your name'),
            email: yup.string().email('Invalid email address').required('Please enter your email'),
            organization: yup.string().required('Please enter your organization name'),
            phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10).max(10).required('Please enter your phone number'),
            designation: yup.string().required('Please enter your designation'),
            address: yup.string().required('Please enter your address'),
            website: yup.string().matches(webreg, 'Please enter a valid website').required('Please enter your website')
        }),
        onSubmit: (values) => {
            // formik.values.phone = phoneNumber

            if(!isVerified){
                toast.error('Please verify you email')
                return;
            }
            setEmail(values.email)
            if (!selectedCountry) {
                toast.error("Please select country")
                return
            } else if (!selectedState) {
                toast.error("Please select state")
                return
            } else if (!selectedCity) {
                toast.error("Please select city")
                return
            }
            const customer = {
                "name": values.name,
                "organization_name": values.organization,
                "email": values.email,
                "mobile": values.phone,
                "designation": values.designation,
                "address": values.address,
                "website": values.website
            }
            if (customer) {
                if (!selectedCity) {
                    toast.error("Please select country, state and city")
                } else {
                    const organization = {
                        "name": values.organization,
                        "country": selectedCountry,
                        "state": selectedState,
                        "city": selectedCity,
                        "address": values.address,
                        "website": values.website,
                        "status": "Enable",
                    }

                    dispatch(createOrganization(organization, customer))
                }
            }

        },
    });


    function handleVerifyEmail() {
        if (formik.errors.email || !formik.values.email) {
            toast.error("Please enter valid email address")
            return;
        }
        dispatch(getOtpRequest(formik.values.email, 'customer'))
    }

    useEffect(() => {
        if (success) {
            console.log("successfulllyyyyy")
            toast.success("Registered Successfully, please login to continue...")
        }
    }, [success])

    useEffect(() => {
        if (error?.message === "DUPLICATE_NAME") {
            toast.error("Organization name already exists")
        } else if (error?.message === "DUPLICATE_WEBSITE") {
            toast.error("Organization website already exists")
        } else if (error?.message === "Validation error") {
            toast.error("Please check all the details")
        } else if (error?.message === "DUPLICATE_EMAIL") {
            toast.error("Please enter a different email")
        } else if (error?.message === "DUPLICATE_MOBILE") {
            toast.error("Please enter a different phone number")
        }

        if (otpError) {
            toast.error(otpError)
            dispatch(resetOtpRequest())
        }
    }, [error, otpError])

    function handleVerifyOtp(){
        const otp:any = inputRef.current.value;
        if(!!otp){
            dispatch(verifyOtpRequest(formik.values.email,key,otp,'customer'))
            return;
        }else{
            toast.error('Please enter a valid otp')
        }
    }

    useEffect(()=>{
        if(verifySuccess){
            toast.success("Email verified successfully")
            setIsVerified(true)
        }
    },[verifySuccess])



    useEffect(() => {

        let newDate = new Date();
        newDate.setDate(new Date().getDate() + 7);

        if (organization) {
            const customer = {
                "name": formik.values.name,
                "organization_name": formik.values.organization,
                "email": formik.values.email,
                "mobile": formik.values.phone,
                "country": selectedCountry,
                "state": selectedState,
                "city": selectedCity,
                "designation": formik.values.designation,
                "address": formik.values.address,
                "website": formik.values.website,
                "organization_id": organization.id,
                "status": "Enable"
            }
            dispatch(createCustomer(customer))
        }
    }, [organization])

    useEffect(() => {
        if (registeredCustomer) {
            let newDate = new Date();
            newDate.setDate(new Date().getDate() + 7);

            const approval = {
                "title": "Customer-Organization approval",
                "type": 5,
                "assigned_by": 110,
                "assigned_to": 105,
                "status": 1,
                "customer_id": registeredCustomer?.id,
                "description": `Review and approve customer request to be added to organization with id ${registeredCustomer?.organization_id}`,
                "comment": "Kindly approve the addition of this customer to the organization's database",
                "due_date": newDate
            }

            dispatch(createApproval(approval))
            dispatch(resetState())


        }
    }, [registeredCustomer])





    useEffect(() => {

        if (id) {
            console.log(id, "id")
            toast.success("Registered Successfully, please login to continue...")

            navigate('/login', { state: email })
            // window.location.reload()
        }
    }, [customer])

    function handleCountryChange(e: any) {
        setSelectedCountry(e.target.value)
        console.log(selectedCountry, "country")
    }


    return (
        <div className="register-container2">
            <Row className="main-row">
                <Col className="logo_footer">
                    <div className="login__logo3">
                        <NavLink to='/'> <img src={logo2} alt='logo' /></NavLink>
                    </div>

                    <div className='form-holder'>
                        <Container className='reg-form-container'>
                            <Form onSubmit={formik.handleSubmit}>
                                <h2>Sign up</h2>
                                <p id='reg-welcome'>Welcome, Please enter your details!</p>

                                <Row>
                                    <Form.Group as={Col} className='mb-3'>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" id='name' placeholder="Enter Name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={state ? state?.name : formik.values.name} />
                                        {formik.touched.name && formik.errors.name ? <p className='error'>{formik.errors.name}</p> : null}
                                    </Form.Group>

                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>Organisation Name</Form.Label>
                                        <Form.Control type="text" id='organization' placeholder="Enter organization" onBlur={formik.handleBlur} onChange={formik.handleChange} value={state ? state?.organization : formik.values.organization} />
                                        {formik.touched.organization && formik.errors.organization ? <p className='error'>{formik.errors.organization}</p> : null}
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" id='email' placeholder="johndoe@example.com" onBlur={formik.handleBlur} onChange={formik.handleChange} value={state ? state?.email : formik.values.email} />
                                        {/* {formik.touched.email && formik.errors.email ? <p className='error'>{formik.errors.email}</p> : null} */}
{    !isVerified &&                                   <div className="d-flex verify-email">
                                            <span className="text-muted" style={{ 'cursor': 'pointer', fontSize: '13px' }} onClick={handleVerifyEmail}>
                                                Verify
                                            </span>
                                            {otpSuccess &&
                                                <>
                                                    <input type="text" placeholder='enter otp' ref={inputRef}/>
                                                    <Button className='btn btn-light' onClick={handleVerifyOtp}>Submit</Button>
                                                </>
                                            }
                                        </div>}

                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <Form.Control type="text" id='phone' placeholder="Enter phone number" onBlur={formik.handleBlur}
                                            onChange={formik.handleChange} value={state ? state?.phone : formik.values.phone} />
                                        {formik.touched.phone && formik.errors.phone ? <p className='error'>{formik.errors.phone}</p> : null}
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>Designation</Form.Label>
                                        <Form.Control type="text" id='designation'
                                            placeholder='Enter Designation' onBlur={formik.handleBlur} onChange={formik.handleChange} value={state ? state?.designation : formik.values.designation} />
                                        {formik.touched.designation && formik.errors.designation ? <p className='error'>{formik.errors.designation}</p> : null}
                                    </Form.Group>

                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>Website</Form.Label>
                                        <Form.Control type="text" id='website'
                                            placeholder='Enter website' onBlur={formik.handleBlur} onChange={formik.handleChange} value={state ? state?.website : formik.values.website} />
                                        {formik.touched.website && formik.errors.website ? <p className='error'>{formik.errors.website}</p> : null}

                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} className='mb-3'>
                                        <Form.Label>Country</Form.Label>
                                        <Form.Select className='custom-select' aria-label="Select country" onChange={handleCountryChange}>

                                            <option className='options' value="">Select country</option>
                                            {countries.map((country: any) => {
                                                return (
                                                    <option className='options' key={country.isoCode} value={country.isoCode}>{country.name}</option>
                                                )
                                            })}
                                        </Form.Select>
                                    </Form.Group>


                                    <Form.Group as={Col} className='mb-3'>
                                        <Form.Label>State</Form.Label>
                                        <Form.Select aria-label="Select state" onChange={(e: any) => {
                                            console.log(e.target.value, "state")
                                            setSelectedState(e.target.value)
                                        }}>

                                            <option className='options'>Select state</option>
                                            {states.sort((state1, state2) => (state1.name > state2.name) ? 1 : -1).filter((state: any) => {
                                                return state.countryCode === selectedCountry
                                            }).map((state: any) => {
                                                return (<option className='options' key={state.isoCode} value={state.isoCode} > {state.name}</option>)
                                            })}



                                        </Form.Select>
                                    </Form.Group>
                                    {/* <Form.Control type="text" id='state' placeholder="Enter state" onChange={formik.handleChange} value={formik.values.state} /> */}


                                </Row>

                                <Row>

                                    <Form.Group as={Col} className='mb-3'>
                                        <Form.Label>City</Form.Label>
                                        <Form.Select aria-label="Select city" onChange={(e: any) => {
                                            setSelectedCity(e.target.value)
                                        }}>
                                            <option className='options' value="">Select city</option>
                                            {cities.filter((city: any) => {
                                                return city.stateCode === selectedState
                                            }).map((city: any) => {
                                                return (<option className='options' key={city.name} value={city.name}>{city.name} </option>)
                                            })}

                                        </Form.Select>

                                    </Form.Group>
                                    {/* <Form.Control type="text" id='city' placeholder="Enter city" onChange={formik.handleChange} value={formik.values.city} /> */}

                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control type="text" id='address'
                                            placeholder='Enter Address here...' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.address} />
                                        {formik.touched.address && formik.errors.address ? <p className='error'>{formik.errors.address}</p> : null}
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3 remember-me">
                                    <Form.Check type="checkbox" label="Keep me signed in" defaultChecked />
                                    {/* <p className="need-help"> <a href='#'> need help? </a></p> */}
                                </Form.Group>

                                <Button  className='signup-btn w-100 mb-3' type="submit">
                                    Signup Now
                                </Button>

                                <div className="account">
                                    <p id='reg'>Already have an account? <span><Link to={"/login"}> login </Link></span></p>
                                </div>

                                {/* <Button className='brownBtn w-100' variant="primary" type="submit">
                                    Send Message
                                </Button> */}
                            </Form>
                        </Container>

                    </div>

                    <div className="footer-links2">
                        <ul>
                            <li><Nav.Link href='/#about-us'>About us</Nav.Link></li>
                            <li><NavLink to='/'>Privacy Policy</NavLink></li>
                            <li><NavLink to='/termsCondition'>Terms of use</NavLink></li>
                        </ul>
                        <div className="copy-right2">
                            <p>Copyright &copy; 2022. All Rights Reserved</p>
                        </div>
                    </div>

                </Col>
            </Row>


        </div >
    )
}

export default Registration