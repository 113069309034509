/* eslint-disable */
import React, { useEffect } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Breadcrumb from './breadcrumb';
// import Filters from './filter';
import ProductCard from './ProductCard';
import { useState } from 'react';
import productBanner from '../../assets/img/productBanner.webp';
// import productBanner from '../../assets/img/Noproducts/productBanner.webp';
import { fetchProducts, sendEnquiry } from '../../redux/actions/productActions';
import { useAppDispatch, useAppSelector } from "../../redux/hook"
import { Form, Button } from "react-bootstrap"
import useDebounce from './hooks/useDebounce';
import { getCustomerDetails } from '../../redux/actions/loginActions';


function ProductDetail() {
    const [cName, setClassName] = useState('jsGridView');
    const [searchQuery, setSearchQuery] = useState("")

    // const getproductViewClass = (className: string) => {
    //     setClassName(className)
    // }

    const dispatch = useAppDispatch();

    const allProducts = useAppSelector((state: any) => state?.allProducts?.products);
    // console.log(allProducts, 'allProducts')


    const setDebouncedSearch = useDebounce(searchQuery, 500)

    const userId = localStorage.getItem("userId")
    useEffect(() => {
        if (userId) {
            dispatch(getCustomerDetails(userId))
        }

    }, [userId])



    useEffect(() => {
        dispatch(fetchProducts(setDebouncedSearch))

    }, [setDebouncedSearch])




    return (
        <div className='ProductDetailSec'>
            <Header />
            <div className="innerDetails">
                <div className='productBanner'>
                    <img src={productBanner} className='banImg' alt='banner' />
                    <div className='container'>
                        <Breadcrumb />
                    </div>
                </div>

                <div className="container mt-5">

                    {/* <Filters viewHandler={getproductViewClass} /> */}

                    <Form className="d-flex mb-4">
                        <Form.Control
                            // type="search"
                            placeholder="Search products..."
                            className="remove-focus p-2"
                            type="text"
                            value={searchQuery}
                            // aria-label="Search"
                            onChange={(e: any) => { 
                                setSearchQuery(e.target.value) }}
                        />
                        <Button className='search-btn' onClick={() => {
                            dispatch(fetchProducts(searchQuery))
                        }}>Search</Button>
                    </Form>

                    <div className={"row " + cName}>
                        <ProductCard allproducts={allProducts} />
                    </div>
                </div>

            </div>


            <Footer />
        </div>
    )
}

export default ProductDetail