// import { APICore } from 'helpers/api/apiCore';
// import { LoginState, Response } from "redux/types/DataTypes";
// import { ActionTypes as ActionTypes } from ".././constants/action-types";
import { ActionTypes } from "../constants/action-types";

// const api = new APICore();

const INIT_STATE = {
    loading: false,
    email: localStorage.getItem('email') || "",
    key: "",
    otp: "",
    isValidSession: false,
    error: null,
    session: {
        sessionId: localStorage.getItem('token'),
        privileges: [],
        role: '',
        userType: localStorage.getItem('userType'),
        userId: localStorage.getItem('userId'),
    },
    success: false,
    resendOtpSuccess: false,
    customer: {},
    verifySuccess: false,
    registeredCustomer: {},
    customerExist: {},
    logout: false,
    organization: {},
    errors: null,
    response: {},
    approvalDetails: {},
};


//! Login Reducer 
const loginReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case ActionTypes.OTP_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                email: action.payload.email,
                key: action.payload?.data?.response?.key,
                error: null,
                success: true,
                response: action.payload?.data?.response,
                otpSent:true

            };
        case ActionTypes.OTP_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                success: false,
                otpSent:false,
                otpError:action.payload.error

            };
        case ActionTypes.RESET_OTP_REQUEST:
            return {
                ...state,
                otpError:null
            }
        case ActionTypes.VERIFY_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                isValidSession: action.payload?.data?.response?.isValidSession,
                session: {
                    sessionId: action.payload?.data?.response?.session?.sessionId,
                    userType: action.payload?.data?.response?.session?.userType,
                    userId: action.payload?.data?.response?.userId
                },
                verifySuccess: true,
                logout: false

            };
        case ActionTypes.VERIFY_OTP_FAIL:
            return {
                ...state,
                // loading: false,
                error: action?.payload?.error?.response?.data?.errorMsg,
                otpError:action?.payload?.error?.response?.data?.errorMsg
                // success: false
            };
        case ActionTypes.RESEND_OTP_SUCCESS:
            return {
                ...state,
                resendOtpSuccess: true,
            };
        case ActionTypes.RESEND_OTP_FAIL:
            return {
                ...state,
                resendOtpSuccess: false,
            };
        case ActionTypes.EDIT_MAIL:
            return {
                ...state,
                key: "",
                email: "",
                isExist: false
            };
        case ActionTypes.LOGOUT:
            return {
                ...state,
                key: "",
                email: "",
                otp: "",
                error: null,
                isValidSession: false,
                logout: true,
                session: {
                    sessionId: "",
                    privileges: [],
                    role: '',
                    userType: "",
                    userId: ""
                },

            }
        case ActionTypes.GET_USER_DETAIL:
            return {
                ...state,
                customer: action.payload.data,
            }
        case ActionTypes.GET_USER_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload.error
            }
        case ActionTypes.CREATE_USER_SUCCESS:
            return {
                ...state,
                registeredCustomer: action.payload.data,
                error: null,
                success: false
            }
        case ActionTypes.CREATE_USER_FAIL:
            console.log(action.payload, "action.payload.error")
            return {
                ...state,
                error: action?.payload?.error
            }
        case ActionTypes.FETCH_CUSTOMER:
            return {
                ...state,
                customerExist: action?.payload?.response?.customer,
                registeredCustomer: {}
            }
        case ActionTypes.FETCH_CUSTOMER_FAIL:
            return {
                ...state,
                error: action.payload.error
            }
        case ActionTypes.CREATE_ORGANIZATION:
            return {
                ...state,
                organization: action.payload
            }
        case ActionTypes.CREATE_ORGANIZATION_FAIL:
            return {
                ...state,
                error: action?.payload?.error
            }
        case ActionTypes.RESET_STATE:
            return {
                ...state,
            }

        case ActionTypes.CREATE_APPROVAL_SUCCESS:
            return {
                ...state,
                approvalDetails: action.payload.data,
                registeredCustomer: {},
                error: null
            }
        case ActionTypes.CREATE_APPROVAL_FAIL:
            return {
                ...state,
                error: action.payload.error
            }
        default:
            return { ...state };
    }
};

export default loginReducer;
