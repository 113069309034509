import Container from "react-bootstrap/Container";
import Header from "../Header";
import Footer from "../Footer";

function PrivacyPage() {
  return (
    <div className="TermsPage">
      <Header />
      <Container>
        <section className="contentPage">
          <h1 className="text-brown mb-3">Privacy Policy – Ben & Gaws Private Limited (“B&G”)</h1>

          <p>Welcome to bengaws.com (including the related mobile site) (hereinafter collectively  referred to as <strong>“Platform”</strong>). Your usage of the Platform shall be subject to the  guidelines, terms and conditions set out herein. This Privacy Policy constitutes an  electronic record within the meaning of the applicable law. This electronic record is  generated by a computer system and does not require any physical or digital  signatures. </p>
          <p>By using or continuing to use the Platform, you agree to our use of your information  (including sensitive personal information) in accordance with this Privacy Policy, as  may be amended from time to time by the Platform in its discretion. You also agree  and consent to us collecting, storing, processing, transferring, and sharing information  (including sensitive personal information) related to you with third parties or service  providers for the purposes as set out in this Privacy Policy.</p>
          <p>This Privacy Policy is published in compliance with inter alia: 
          </p>
          <ul>
            <li>Section 43A of the Information Technology Act, 2000 (<strong>“IT Act”</strong>); and • Rule 4 of the Information Technology (Reasonable Security Practices and  Procedures and Sensitive Personal Data or Information) Rules, 2011 (<strong>“SPDI  Rules”</strong>).</li>
          </ul>

          <h2>IMPORTANT DISCLAIMER </h2>

          <p>The Platform cares how information about you is used and shared and appreciates your trust in the Platform to do that carefully and sensibly. Access of the Platform shall be deemed to be your acceptance of the guidelines, terms and conditions set out herein and that we may process and use your personal information in accordance with the terms and conditions of this Privacy Policy. Please read the Privacy Policy carefully before using the Platform. If you do not wish to be bound by this Privacy Policy, then you should not access this Platform. Please note that usage of the Platform without reading this Privacy Policy shall be at your own risk.</p>
          <h4>Age Restriction</h4>
          <p>The activity on the Platform is not intended for use by minors. If you are a minor i.e. under the age of 18 years, you may use the Platform only with involvement of a parent or guardian. Platform and its affiliates reserve the right to refuse service, terminate accounts, or remove or edit content in their sole discretion. The use of this Platform is only available to persons who can form a legally binding contract under the Indian Contract Act, 1872.</p>

          <h4>Collection of Information</h4>
          <p>We store the information collected from the Platform and may use it to:</p>
          <ul>
            <li>that a visitor may download information, products and take advantage of certain other features of B&amp;G&rsquo;s website;</li>
            <li>to provide information or interactive services through this website, to the visitor&rsquo;s e-mail address or, where the visitor wishes it to be sent by post, to the visitor&rsquo;s postal address;</li>
            <li>to seek the visitor&rsquo;s feedback or to contact the visitor in relation to the services offered on B&amp;G&rsquo;s website;</li>
            <li>to process orders or applications submitted by the visitor;</li>
            <li>to administer or otherwise carry out B&amp;G&rsquo;s obligations in relation to any agreement that the visitor may have with B&amp;G;</li>
            <li>to anticipate and resolve problems with any goods or services supplied to the visitor;</li>
            <li>to create products or services that may meet the visitor&rsquo;s needs; or</li>
            <li>to process and respond to requests, improve B&amp;G&rsquo;s operations, and communicate with visitor/s about B&amp;G&rsquo;s products, services and businesses.</li>
          </ul>
          <p>B&amp;G shall collect only minimum Information required to meet the purposes mentioned in this policy. Neither B&amp;G nor its representatives shall be responsible for the authenticity of such Information provided by the visitor/s. As normal business practice, B&amp;G may collect Information in order to enable the secure online authentication, interaction and transaction with natural persons. This may include the installation of cookies and the collection of other session data.</p>

          <h4>Types of Information Collected</h4>
          <p>We may store information you enter on the Platform or give us in any other way.
            Examples of the information that may be collected, include: the Internet protocol (IP)  address used to connect your computer to the Internet; login; e-mail address;  password; computer and connection information such as browser type and version;  operating system and platform; user history; the full Uniform Resource Locators (URL)  clickstream to, through and from the Platform (including date and time); cookie  number; any phone number used to call our customer service etc.
            The information that we collect from you depends on how you use the Platform. In the  event you contact us through the Platform, we will collect your name and contact  information, including your e-mail address and company’s name.
            We may also use browser data such as cookies, Flash cookies (also known as Flash  Local Shared Objects), or similar data for fraud prevention and other purposes. During  some visits we may use software tools to measure and collect session information,  including page response times, download errors, length of visits to certain pages, page  interaction information (such as scrolling, clicks, and mouse-overs), and methods used  to browse away from the page.
            You can choose not to provide certain information but then you might not be able to  take advantage of many of the features provided on the Platform. We use the
            information that you provide for purposes such as responding to your requests,  customising user experience for you, improving the Platform, and communicating with  you.
            In addition, we may receive and store certain types of information whenever you  interact with us. It may include information about your location and your mobile device,  including a unique identifier for your device. We may use this information for internal  analysis and to provide you with location-based services, such as advertising, search  results, and other personalized content.</p>
          <h4>Preventing your browser from accepting cookies
          </h4>
          <p>The Help menu on the menu bar of most internet browsers will tell you how to prevent  your browser from accepting new cookies, how to have the browser notify you when  you receive a new cookie and how to disable cookies altogether. Additionally, you can  disable or delete similar data used by browser add-ons, such as Flash cookies, by  changing the add-on's settings or visiting the website of its manufacturer.
            However, because cookies allow you to take advantage of the essential features, we  recommend that you leave them turned on.
            If you do leave cookies turned on, be sure to sign off when you finish using a shared  computer.</p>
          <h4>Security of Information
          </h4>
          <p>The Platform strives to take all reasonable efforts to protect the security of your  information during transmission by using (<strong>“Secure Sockets Layer (SSL) software”</strong>),  which encrypts information you input in addition to maintaining security of your  information as per the International Standard IS/ISO/IEC 27001 on "Information  Technology Security Techniques Information Security Management System
            Requirements" and/or other security measures as provided under Rule 8 of the SPDI  Rules.
            We strive to maintain physical, electronic and procedural safeguards in connection  with the collection, storage and disclosure of personal information (including sensitive  personal information). Our security procedures mean that we may occasionally  request proof of identity before we disclose personal information to you.
            It is important for you to protect against unauthorised access to your password and to  your computer. Be sure to sign off when you finish using a shared computer.  </p>
          <h4>Legitimate Interests
          </h4>
          <p>The Platform has carried out balancing tests for all the data processing used by the  Platform on the basis of our legitimate interests.
            <strong> Withdrawing consent or otherwise objecting to direct marketing/profiling </strong> 
             Wherever the Platform relies on the user consent, the user will always be able to  withdraw that consent, however, please note, the Platform may have other legal  grounds for processing your data for other purposes, such as those set out above. In  some cases, we may be able to send you direct marketing without your consent, where  we rely on our legitimate interests. You have an absolute right to opt-out of direct  marketing, or profiling we carry out for direct marketing, at any time. You can do this  by contacting us.

          </p>
          <h4>Notices and Revisions </h4>
          <p>If you have any concern about privacy or grievances at the Platform, please contact  us with a thorough description and we will try to resolve the issue for you.
            Our Privacy Policy may change from time to time. We may e-mail periodic reminders  of our notices and conditions, unless you have instructed us not to, but you should  check our website frequently to see recent changes.
            Unless stated otherwise, our current Privacy Policy applies to all information that we  have about you.
            Other than as set out above, you will receive notice when information about you might  go to third parties and you will have an opportunity to choose not to share the  information. </p>
          <h4>User Rights</h4>
          <p> As discussed above, you can always choose not to provide information, even though  it might be needed to avail features on the Platform.
            You can add or update certain information on pages where your personal details are  stored. When you update information, we usually keep a copy of the previous version  for our records.
            The user has the right to request a copy of their personal information; to correct, delete  or restrict (stop any active) processing of their personal information; and to obtain the  personal information you provide to the Platform for a contract or with your consent in  a structured, machine readable format, and to request the Platform to share/ port this  data to another controller.
            In addition, you can object to the processing of your personal information in some  circumstances (in particular, where we don’t have to process the data to meet a  contractual or other legal requirement, or where we are using the data for direct
            marketing).
            These rights may be limited, for example if fulfilling your request would reveal personal  information about another person, where they would infringe the rights of a third party  (including our rights) or if you ask us to delete information which we are required by  law to keep or have compelling legitimate interests in keeping. Relevant exemptions  are included in applicable data protection laws. We will inform you of relevant  exemptions we rely upon when responding to any request you make.
            If you have unresolved concerns, you have the right to complain to an applicable data  protection authority where you live, work or where you believe a breach may have  occurred.
            Before we can respond to a request to exercise the rights listed in this section, you  may be required to verify your identity or your account details. We shall have duration  of 1 month to respond to any or all of such exercising of your rights.

          </p>
          <h4>Third-Party Advertisers and Links to other Websites </h4>
          <p>The Platform may include third-party advertising and links to other Websites.  </p>
          <h4>Sharing of Information </h4>
          <p>Information about our customers is an important part and we are not in the business  of selling it to others. The Platform shares customer information only as per this  Privacy Policy and follows all reasonable practices to ensure protection of data.
            The Platform may have affiliated businesses and may provide services jointly with or  on behalf of these businesses. You can tell when a third party is involved in your  transactions and we share customer information related to those transactions with that  third party.
            The Platform may associate with other companies and individuals to perform certain  functions on its behalf. Examples include sending postal mail and e-mail, removing  repetitive information from customer lists, analysing data, providing marketing  assistance, providing search results and links, processing credit card payments and  providing customer service. They will have access to personal information needed to  perform their functions but may not use it for other purposes. Further, they must  process the personal information in accordance with this Privacy Policy and as  permitted by applicable law.
          </p>
          <h4>Retention of Data
          </h4>
          <p>
            Where we process your financial information, ordinarily, the information will be  retained for as long as necessary for the purpose/s for which it is collected.
            In relation to all your other data including registration data, the Platform will not retain  the users data for longer than required under the applicable law, and will delete data  if they are no longer necessary for the purposes for which they are collected or  otherwise processed, unless they are required to keep the data to comply with  applicable legal obligations.

          </p>
          <h4>Disclosure</h4>
          <p>In some cases, we may be required to disclose your personal information to comply  with legal requirements and requests from government agencies, if mandated by law  or if required for the legal protection of the Platform’s legitimate interests in compliance  with applicable laws. We may also disclose your personal information to our group  companies or to third parties:

            <ul>
              <li>who provide us with payment related, data management and profiling, analytics,  advertising or other services in order to process your bookings, provide  information about tailored and individualized services/offers including on social  media, and provide you with any information that you have requested; </li>
              <li>where we consider necessary to in order to comply with any law or regulation,  where we suspect that any criminal offence may have been committed, to protect  our rights, property or safety or that of others and in any circumstances where we  consider that we are permitted to do so by law or regulation; and </li>
              <li> where that third party is a professional adviser to us or any of our group  companies.
              </li>
            </ul>
            In the event that the business is sold or integrated with another business, your details  will be disclosed to our advisers and any prospective purchaser’s adviser and will be  passed to the new owners of the business.
            The Platform may contain links to other websites belonging to third parties. We do not  control the privacy practices of these other websites. The user should therefore ensure  that the said website’s privacy policy has been read and conformed to.
            As we continue to develop our business, we might sell or buy stores, subsidiaries or  business units. In such transactions, customer information generally is one of the  transferred business assets but remains subject to the promises made in any pre existing privacy policy. Also, in the event that the Platform or substantially all of its  assets are acquired, customer information will of course be one of the transferred assets.
            We release account and other personal information when we believe release is  appropriate to comply with applicable law; or protect the rights, property or safety of
            Platform, our users or others. This includes exchanging information with other  companies, organisations, government or regulatory authorities for fraud protection  and credit risk reduction. Obviously, however, this does not include selling, renting,  sharing or otherwise disclosing personally identifiable information from customers for  commercial purposes in a way that is contrary to the commitments made in this Privacy  Policy.
            We might receive information about you from other sources and add it to our account  information.
          </p>
          <h4>Copyright, Trademarks and Licenses
          </h4>
          <p>Copyright is applicable to all content included on the Platform, such as text, graphics,  logos, button icons, images, audio clips, digital downloads, data compilations, and  software, and is the property of the Platform or its affiliates or its content suppliers and  protected by jurisdictional laws of India. The compilation of all content on this Platform  is the exclusive property of the Platform or its affiliates and protected by the Indian and  international copyright laws.
            The Platform grants you a limited license to access and make personal use and not to  download (other than page caching) or modify it, or any portion of it, except with  express written consent of Platform and / or its affiliates, as may be applicable.
            This license does not include any resale or commercial use of this Platform or its  contents; any derivative use of this Platform or its contents; or any use of data mining,  robots, or similar data gathering and extraction tools.
            This Platform or any portion of this Platform may not be reproduced, duplicated,  copied, sold, resold, visited, or otherwise exploited for any commercial purpose without  express written consent of Platform and / or its affiliates.
          </p>
          <h4>Disclaimer of warranties and limitation of liabilities
          </h4>
          <p>The Platform is provided on an “as is” and “as available” basis. The Platform makes  no representations or warranties of any kind, express or implied, as to its operation or  the information, content, materials, or products included. You expressly agree that  your use of the Platform is at your sole risk. The Platform reserves the right to withdraw  or delete any information at any time in its discretion.
            To the full extent permissible by applicable law, Platform disclaims all warranties,  express or implied, including, but not limited to, implied warranties of fitness for a  particular purpose. The Platform does not warrant that its servers, or e-mail sent are  free of viruses or other harmful components. The Platform will not be liable for any  damages of any kind arising from the use of this Platform, including, but not limited to  direct, indirect, incidental, punitive, and consequential damages.
            The Platform and its affiliates attempt to keep the content description be as accurate  as possible. However, the Platform does not warrant that description of content is  accurate, complete, reliable, current, or error-free. Also, your access to the Platform  may also be occasionally suspended or restricted to allow for repairs, maintenance, or  the introduction of new facilities or at any time without prior notice. We will attempt to  limit the frequency and duration of any such suspension or restriction. The Platform
            may provide links to other sites over which the Platform has no control and is not  responsible for the availability of such external sites or resources and does not  endorse and is not responsible or liable for any content, advertising, products or other  material on or available from such sites or resources. </p>
          <h4>Modification and Severability
          </h4>
          <p>The Platform reserves the right to make changes to the policies at any time. If any of  these conditions shall be deemed invalid, void, or for any reason unenforceable, that  condition shall be deemed severable and shall not affect the validity and enforceability  of any remaining conditions.
            If you have any queries or complaints regarding this Privacy Policy or use of your  personal information please contact our data protection manager using the contact  details set out below, listed as Grievance Officer.
          </p>
          <h4>Privacy and data usage</h4>
          <p>As a company, we prioritise the privacy and security of our customers and end users, and are committed to using user's personal data in a responsible and ethical manner. We collect user name, email address and mobile number of the user, and will only use this information for contacting the users for the processing of their enquiry about our products. We do not share this personal data with third parties or use it in any other way. By collecting and using the users' personal data in a transparent and secure manner, we aim to build trust with our users and establish a positive reputation as a responsible company.</p>
          <h4>Grievance Officer</h4>
          <p><strong>Name:</strong> Mr. Sushant Baweja<br/>
            <strong>Email:</strong> Sushant.baweja@bengaws.com<br/>
            <strong> Address:</strong> E 48/9, Millennium Building, Okhla Industrial area phase II, New Delhi,  110020.
          </p>



        </section>
      </Container>
      <Footer />
    </div>
  );
}

export default PrivacyPage;
