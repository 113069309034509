/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from "../../redux/hook"
import { fetchSingleProducts, sendEnquiry } from "../../redux/actions/productActions"
import { useParams, useNavigate } from 'react-router-dom';
import useEnquiryValidation from './hooks/useEnquiryValidation';
import QuoteButton from '../Misc/QuoteButton';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { getCustomerDetails } from '../../redux/actions/loginActions';
import Table2 from './Table2';
// import ProductParam from '../ProductDetail/ProductParamt';
import NotifyToast from '../Misc/NotificationToast';
import { clearEnquiryId } from "../../redux/actions/enquiryActions"
import SkuMatrix from './SkuMatrix';
import useSkuMatrix from './hooks/useSkuMatrix';



function About() {
    const [show, setShow] = useState(false);
    const singleData = useAppSelector((state: any) => state.product.singleProductData);
    const dispatch = useAppDispatch();
    const { id } = useParams()
    const userEmail = localStorage.getItem("email")
    const session = localStorage.getItem("session")
    const userId = localStorage.getItem("userId")
    const skuValueString = useRef<any>(null);
    useEffect(() => {
        if (id) {
            dispatch(fetchSingleProducts(id))
            if (userId && session) {
                dispatch(getCustomerDetails(userId))
            }
        }

    }, [id, userId, session])

    const customer = useAppSelector((state: any) => state.customerLogin);
    const navigate = useNavigate();
    const [desc, setDesc] = useState("")
    const status = "Enquiry received"
    const description = desc
    const productId = singleData?.id
    const custId = customer?.customer?.response?.customer?.id


    const sendEnquiryData = {
        customer_id: custId,
        product_id: productId,
        description: description,
        status: status,
        user_id: userId,
        doe: new Date(),
    }

    //! values from the api will be given as input to this function and it will transform the sku fields and values into the string
    function transformSkuValue(skuField: any, skuValue: any) {
        let finalString = ""
        for (const key in skuField) {
            if (key.includes("field")) {
                if (skuField[key] !== null && skuValue[key] !== null) {
                    finalString += `${skuField[key]}:${skuValue[key]}, `
                }
            }
        }
        // console.log(finalString, "resgh")
        // console.log(finalString.trim())
        // console.log("finalString123", finalString.trim(), typeof finalString)
        skuValueString.current = finalString.trim()
        return finalString
    }


    const handleClose = () => {
        setShow(false);
        dispatch(sendEnquiry(sendEnquiryData))
    }

    const handleShow = () => setShow(true);

    const handleClick = () => {
        if (userEmail && session) {
            navigate(`/singleDetail/${id}`, { state: { id: id, form: "" } })
        } else {
            navigate("/login")
        }
    }

    useEffect(() => {
        const flag = localStorage.getItem("sFlag");
        if (flag) {
            setShow(true);
        }

    }, [])

    const productEnq: any = useAppSelector((state: any) => state?.enquiry?.productEnquiry);
    const enqId = productEnq?.response?.enquiry?.id

    useEffect(() => {
        if (enqId) {
            console.log(productEnq, "enqId")
            toast.success(<div className='link_toast'>
                {/* Enquiry sent successfully click here to view */}
                <NotifyToast />
            </div>, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 15000,
                closeOnClick: false,
                className: 'customNotifyToast',
            })

            dispatch(clearEnquiryId())
        }

    }, [enqId])


    return (

        <div className="AboutSec">
            <div >
                <div className="aboutProdct my-3">
                    <div dangerouslySetInnerHTML={{ __html: singleData?.html_desc }} ></div>
                    {userEmail && session ? (
                        <QuoteButton id={id} product={singleData} skuValueString={skuValueString} />
                    ) : (
                        <Button className='brownBtn' variant="brownBtn" onClick={() => {
                            toast.info("Please login to continue")
                            navigate("/login", { state: { id: id, form: "", sFlag: true } })
                        }
                        }>Request a quote</Button>
                    )}

                    {singleData?.sku_matrix ? (
                        <SkuMatrix id={singleData?.id} transformSkuValue={transformSkuValue} />
                    ) : (<></>)
                    }
                </div>
            </div>
        </div>
    )
}

export default About; 